// // src/redux/store.js
// import { createStore } from 'redux';
// import rootReducer from './reducers';

// const store = createStore(
//   rootReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// export default store;

// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import { loadCart, loadUser, saveCart,saveUser } from "../config/localStorage";
import userReducer from "./userSlice";

const preloadedState = {
  cart: loadCart(),
  user: loadUser(),
};

const store = configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
  },
  preloadedState,
});

store.subscribe(() => {
  saveCart(store.getState().cart);
  saveUser(store.getState().user);
});

export default store;
