import React from "react";
import { useState, Fragment } from "react";
import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import Header from "../../components/header/Header";
import PageTitle from "../../components/global/PageTitle";

import BillingFields from "./BillingFields";
import ShippingFields from "./ShippingFields";
import NoscriptSnippet from "../../components/global/NoscriptSnippet";
import LoginUser from "../myAccount/login";
import RegisterUser from "../myAccount/register";

/**
 * CheckoutRegs page
 * @param options
 * @returns {*}
 * @constructor
 */
function CheckoutRegs({ options }) {
  /**
   * states
   */
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const HandleShowLoginStatus = (e) => {
    e.preventDefault();
    HandelCloseTabs();
    setShowLogin(!showLogin);
  };
  const HandleShowRegistrationStatus = (e) => {
    e.preventDefault();
    HandelCloseTabs();
    setShowRegister(!showRegister);
  };

  const HandelCloseTabs = () => {
    setShowLogin(false);
  };

  return (
    <div className="woocommerce">
      <div className="woocommerce-info">
        Returning customer?{" "}
        <a onClick={HandleShowLoginStatus} className="showlogin">
          Click here to login
        </a>
      </div>
      <div className="woocommerce-info">
        New customer ?{" "}
        <a onClick={HandleShowRegistrationStatus} className="showlogin">
          Click here to register
        </a>
      </div>

      {showLogin && (
        <LoginUser
          HandleShowLoginStatus={HandleShowLoginStatus}
          isFromCheckOut={true}
        />
      )}
      {showRegister && (
        <RegisterUser
          HandleShowRegistrationStatus={HandleShowRegistrationStatus}
          isFromCheckOut={true}
        />
      )}
    </div>
  );
}

export default CheckoutRegs;
