// src/components/ProtectedRoute.js

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../config/auth";

/**
 * ProtectedRoute ensures that the user is authenticated before accessing the route.
 * @param {object} props - Props passed to Route.
 */
const ProtectedRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/my-account",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
