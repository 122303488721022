import React, { Fragment } from "react";

/**
 * side info left menu component
 * @param options
 * @returns {*}
 * @constructor
 */
function HeaderLeft({ options }) {
  return (
    <Fragment>
      <div className="header-left">
        <div className="side-info-bars" onClick={options.onSideInfoClick}>
          <span />
          <span />
          <span />
        </div>
        <div
          className={
            "side-info-content " + (options.sideInfo ? "toggle-side-info" : "")
          }
        >
          <button
            className="btn side-info-close-btn"
            onClick={options.onSideInfoClick}
          >
            <i className="ti-close" />
          </button>
          <div
            className="logo"
            style={{
              fontFamily: "Gerlomi",
              fontWeight: "bold",
              color: "#000",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            NANXTYLES
            {/* <img src={process.env.PUBLIC_URL + "/assets/images/slidbar-logo.png"} alt=""/> */}
          </div>
          <div className="text">
            <p>
              Stay ahead of the curve with our latest collection Discover the
              hottest trends in new fashion now.
            </p>
            <ul className="info">
              <li>Contact us:+234-901-829-4051</li>
              <li>Mail us: Nanxtyle70@gmail.com</li>
            </ul>
            <ul className="social-links">
              <li>
                <a href="https://web.facebook.com/ximplexytles">
                  <i className="ti-facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/nanxtyles">
                  <i className="ti-instagram" />
                </a>
              </li>
              {/* <li><a href="#"><i className="ti-pinterest"/></a></li>
                            <li><a href="#"><i className="ti-vimeo-alt"/></a></li> */}
            </ul>
          </div>
        </div>
        <div className="search-area">
          {/* <form>
                        <button type="submit"><i className="fi flaticon-search"/></button>
                        <input type="text" placeholder="Search for.."/>
                    </form> */}
        </div>
      </div>
    </Fragment>
  );
}

export default HeaderLeft;
