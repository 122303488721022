import React, { Fragment } from "react";

/**
 * product Ordering component
 * @returns {*}
 * @constructor
 */
function Ordering({ HandleSorting }) {
  return (
    <Fragment>
      <form className="woocommerce-ordering">
        <select
          name="orderby"
          className="orderby"
          onChange={(event) => HandleSorting(event)}
        >
          <option value="menu_order">All sorting</option>
          <option value="isFeatured">Sort by is Featured</option>
          <option value="createdAt">Sort by Newness</option>
          <option value="price">Sort by price: Low to High</option>
          <option value="price-desc">Sort by price: High to Low</option>
          <option value="stars">Sort by Average Rating</option>
        </select>
        <input type="hidden" name="post_type" defaultValue="product" />
      </form>
    </Fragment>
  );
}

export default Ordering;
