import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../config/url";

import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import { toast } from "react-toastify";

function Products({ HandelQuickViewData, products, ordering }) {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <ul
        className={
          "products " +
          (ordering == 1
            ? "default-column"
            : ordering == 2
            ? "three-column"
            : ordering == 3
            ? "list-view"
            : "")
        }
      >
        {products?.map((item, index) => (
          <li key={index} className="product">
            <div className="product-holder">
              {parseInt(item?.price) < parseInt(item?.oldPrice) ? (
                <div className="product-badge discount">
                  {Math.round(
                    ((parseInt(item?.price) - parseInt(item?.oldPrice)) /
                      parseInt(item?.price)) *
                      100
                  )}
                  %
                </div>
              ) : (
                ""
              )}
              <Link to={`/product-details/${item?.id}`}>
                <img loading="lazy" src={API_URL + item?.mainImg} alt="" />
              </Link>
              <div className="shop-action-wrap">
                <ul className="shop-action">
                  <li>
                    <a
                      href
                      title="Quick view!"
                      data-tip="Quick view!"
                      onClick={(e) => HandelQuickViewData(e, item)}
                    >
                      <i className="fi flaticon-view" />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href
                      title="Add to Wishlist!"
                      data-tip="Add to Wishlist!"
                    >
                      <i className="fi icon-heart-shape-outline" />
                    </a>
                  </li> */}
                  <li>
                    <a
                      href
                      onClick={() => {
                        dispatch(addToCart(item));
                        toast.success("Item added to cart");
                      }}
                      title="Add to cart!"
                      data-tip="Add to cart!"

                    >
                      <i className="fi flaticon-shopping-cart" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="product-info">
              <h4>
                <Link to={`/product-details/${item?.id}`}>{item?.title}</Link>
              </h4>
              <span className="woocommerce-Price-amount amount">
                <ins>
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      <span className="woocommerce-Price-currencySymbol">
                        {"₦"}
                      </span>
                      {item?.price}
                    </bdi>
                  </span>
                </ins>
                {parseInt(item?.price) < parseInt(item?.oldPrice) ? (
                  <del>
                    <span className="woocommerce-Price-amount amount">
                      <bdi>
                        <span className="woocommerce-Price-currencySymbol">
                          {"₦"}
                        </span>
                        {item?.oldPrice}
                      </bdi>
                    </span>
                  </del>
                ) : (
                  ""
                )}
              </span>
              <p className="product-description">{item?.shortDescription}</p>
            </div>
          </li>
        ))}
      </ul>
    </Fragment>
  );
}

export default Products;
