import React, { useEffect, useState } from "react";
import {
  postEmailVerification,
  postOTPVerification,
  registerUser,
} from "../../services";

import Preloader from "../../components/global/Preloader";
import { toast } from "react-toastify";
import { isObjectEmpty } from "../../utils";

function RegisterUser({ isFromCheckOut, HandleShowRegistrationStatus }) {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    // otp: "",
    password: "",
  });
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hash, setHash] = useState();

  useEffect(() => {
    if (isLoading) {
      <Preloader />;
    }
  }, [isLoading]);

  const handleRegistration = async (e) => {
    e.preventDefault();
    const isObjEmpty = isObjectEmpty(formData);
    if (isObjEmpty) return toast.error("Please fill all form input");

    try {
      const { data } = await registerUser(formData);
      toast.success("Account Successfully Created");
      setFormData({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        // otp: "",
        password: "",
      });
      if (isFromCheckOut) {
        HandleShowRegistrationStatus(e);
      }
    } catch (error) {
      console.log("error", error?.response?.data?.error);
      toast.error(error?.response?.data?.error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVerifyEmail = async () => {
    setIsLoading(true);
    if (!formData.email) {
      toast.error("Please enter email address");
      return;
    }

    try {
      const { data } = await postEmailVerification(formData?.email);
      setHash(data);
      setIsLoading(false);
      toast.success(`OTP code sent to ${formData?.email}`);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.error);
    }
  };

  const handleVerifyOtp = async () => {
    setIsLoading(true);
    const otpData = {
      email: formData.email,
      otp: formData.otp,
      hash: hash,
    };
    if (!formData.email || !formData.otp) {
      toast.error("Please enter email and otp");
      return;
    }
    try {
      const { data } = await postOTPVerification(otpData);
      if (data === false) toast.error("Invalid OTP code");
      if (data === true) {
        setIsEmailVerified(true);
        toast.success("Email verified");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Invalid OTP code");
    }
  };

  return (
    <div className="u-column2 col-2">
      <h2>Register</h2>
      <h5>{isFromCheckOut ? "Please Login after creating account" : ""}</h5>
      <form
        onSubmit={handleRegistration}
        className="woocommerce-form woocommerce-form-register register"
      >
        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
          <label htmlFor="reg_email">
            Email address&nbsp;<span className="required">*</span>
          </label>
          <div className="flex" style={{ display: "flex" }}>
            <input
              type="email"
              onChange={handleChange}
              required
              className="woocommerce-Input woocommerce-Input--text input-text"
              name="email"
              id="reg_email"
              autoComplete
              value={formData.email}
            />
            {/* {!hash && (
              <button
                type="button"
                className="woocommerce-Input woocommerce-Input--text input-text"
                onClick={handleVerifyEmail}
              >
                SendOtp
              </button>
            )} */}
          </div>
          <p></p>
          {/* {hash && !isEmailVerified && (
            <div className="flex" style={{ display: "flex" }}>
              <input
                type="number"
                onChange={handleChange}
                required
                className="woocommerce-Input woocommerce-Input--text input-text"
                name="otp"
                id="otp"
                style={{ flex: 1 }}
                placeholder="Enter the otp code sent to your email"
              />
              <button
                type="button"
                className="woocommerce-Input woocommerce-Input--text input-text"
                onClick={handleVerifyOtp}
              >
                Verify
              </button>
            </div>
          )} */}
        </p>
        {/* <p>An OTP Code will be sent to your email for verification.</p> */}

        {/* {isEmailVerified && ( */}
        <>
          <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
            <label htmlFor="firstName">
              First Name&nbsp;
              <span className="required">*</span>
            </label>
            <input
              type="text"
              onChange={handleChange}
              required
              className="woocommerce-Input woocommerce-Input--text input-text"
              name="firstName"
              id="firstName"
              autoComplete
              value={formData.firstName}
            />
          </p>
          <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
            <label htmlFor="lastName">
              Last Name&nbsp;<span className="required">*</span>
            </label>
            <input
              type="text"
              onChange={handleChange}
              required
              className="woocommerce-Input woocommerce-Input--text input-text"
              name="lastName"
              id="lastName"
              autoComplete
              value={formData.lastName}
            />
          </p>
          <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
            <label htmlFor="phone">
              Phone Number&nbsp;
              <span className="required">*</span>
            </label>
            <input
              type="text"
              onChange={handleChange}
              required
              className="woocommerce-Input woocommerce-Input--text input-text"
              name="phone"
              id="phone"
              autoComplete
              value={formData.phone}
            />
          </p>
          <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
            <label htmlFor="password">
              Password&nbsp;<span className="required">*</span>
            </label>
            <input
              type="password"
              onChange={handleChange}
              required
              className="woocommerce-Input woocommerce-Input--text input-text"
              name="password"
              id="password"
              value={formData.password}
            />
          </p>
          <div className="woocommerce-privacy-policy-text">
            <p>
              Your personal data will be used to support your experience
              throughout this website, to manage access to your account, and for
              other purposes described in our{" "}
              <a className="woocommerce-privacy-policy-link">privacy policy</a>.
            </p>
          </div>

          <p className="woocommerce-form-row form-row">
            <button
              type="submit"
              className="woocommerce-Button woocommerce-button button woocommerce-form-register__submit"
              name="register"
              value="Register"
              style={{
                // opacity: isEmailVerified === true ? 1 : 0.2,
                width: "100%",
                display: "block",
              }}
              // disabled={!isEmailVerified}
            >
              Register
            </button>
          </p>
        </>
        {/* // )} */}
      </form>
    </div>
  );
}

export default RegisterUser;
