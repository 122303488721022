import React, { useEffect, useState } from "react";
import Preloader from "../../components/global/Preloader";
import { toast } from "react-toastify";
import { isObjectEmpty } from "../../utils";
import { loginUser } from "../../services";

import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../../redux/userSlice";

function LoginUser({ isFromCheckOut, HandleShowLoginStatus }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { loading, error, user } = useSelector((state) => state.user);
  useEffect(() => {
    if (loading) {
      <Preloader />;
    }
    if (error) {
      toast.error(`${error.error}`);
    }
    if (user) {
      toast.success("Login Successful");
      setFormData({
        email: "",
        password: "",
      });
      if (isFromCheckOut) {
        HandleShowLoginStatus(Event);
      }
      if (!isFromCheckOut) {
        window.location.href = "/";
      }
    }
    return () => {
      dispatch(clearError());
    };
  }, [loading, error, user, isFromCheckOut, dispatch]);
  // useEffect(() => {
  //   if (loading) {
  //     <Preloader />;
  //   }
  //   if (error) {
  //     toast.error(`${error.error}`);
  //   }
  //   return () => {
  //     dispatch(clearError());
  //   };
  // }, [loading, error, dispatch]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const isObjEmpty = isObjectEmpty(formData);
    if (isObjEmpty) return toast.error("Please fill all form input");
    await dispatch(
      loginUser({ email: formData.email, password: formData.password })
    );
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="u-column1 col-1">
      <h2>Login</h2>
      <form
        className="woocommerce-form woocommerce-form-login login"
        onSubmit={handleLogin}
      >
        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
          <label htmlFor="email">
            Email address&nbsp;
            <span className="required">*</span>
          </label>
          <input
            type="text"
            className="woocommerce-Input woocommerce-Input--text input-text"
            name="email"
            id="email"
            onChange={handleChange}
            value={formData.email}
          />
        </p>
        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
          <label htmlFor="password">
            Password&nbsp;<span className="required">*</span>
          </label>
          <input
            className="woocommerce-Input woocommerce-Input--text input-text"
            type="password"
            name="password"
            id="password_login"
            onChange={handleChange}
            value={formData.password}
          />
        </p>
        <p className="form-row">
          <button
            type="submit"
            className="woocommerce-button button woocommerce-form-login__submit"
            name="login"
            value="Log in"
            style={{ width: "100%" }}
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login"}
          </button>
        </p>
        {/* <p className="woocommerce-LostPassword lost_password">
          <a href="#">Lost your password?</a>
        </p> */}
      </form>
    </div>
  );
}

export default LoginUser;
