import React, { useState, Fragment, useEffect } from "react";

import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import Header from "../../components/header/Header";
import PageTitle from "../../components/global/PageTitle";
import Ordering from "../../components/shop/Ordering";
import QuickView from "../../components/products/QuickView";
import Pagination from "../../components/global/Pagination";
import OrderingToolbar from "../../components/shop/OrderingToolbar";
import Products from "../../components/shop/Products";

import "./shop.css";
import Preloader from "../../components/global/Preloader";
import { useProducts } from "../../hooks/useProduct";

/**
 * Shop page Full Width
 * @param options
 * @returns {*}
 * @constructor
 */
function FullWidth({ options }) {
  const { data, error, isLoading } = useProducts();
  const [allProduct, setAllProduct] = useState([]);

  useEffect(() => {
    if (isLoading) {
      <Preloader />;
    }
    setAllProduct(data);
  }, [isLoading, data]);

  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewData, setQuickViewData] = useState({});
  const [ordering, setOrdering] = useState(1);

  /**
   * Handle Ordering Status
   */
  const HandleOrderingStatus = (event, data) => {
    event.preventDefault();
    setOrdering(data);
  };

  const HandleSorting = (event) => {
    const value = event.target.value;
    let result;
    switch (value) {
      case "isFeatured":
        result = data?.data?.filter((prod) => prod?.isFeatured === true);
        setAllProduct({ data: result });
        break;
      case "createdAt":
        result = allProduct?.data
          ?.slice()
          .sort((firstItem, secondItem) => secondItem?.id - firstItem?.id);
        setAllProduct({ data: result });
        break;
      case "price":
        result = allProduct?.data
          ?.slice()
          .sort(
            (firstItem, secondItem) => firstItem?.price - secondItem?.price
          );
        setAllProduct({ data: result });
        break;
      case "price-desc":
        result = allProduct?.data
          ?.slice()
          .sort(
            (firstItem, secondItem) => secondItem?.price - firstItem?.price
          );
        setAllProduct({ data: result });
        break;
      case "stars":
        result = allProduct?.data
          ?.slice()
          .sort(
            (firstItem, secondItem) => secondItem?.stars - firstItem?.stars
          );
        setAllProduct({ data: result });
        break;

      default:
        setAllProduct(data);
        break;
    }
  };

  /**
   * Handel Quick View Data
   */
  const HandelQuickViewData = (e, item) => {
    e.preventDefault();
    setShowQuickView(!showQuickView);
    setQuickViewData(item);
  };

  /**
   * Handel Quick View Close
   */
  const HandelQuickViewClose = (e) => {
    e.preventDefault();
    setShowQuickView(false);
    setQuickViewData({});
  };

  return (
    <Fragment>
      {showQuickView ? (
        <QuickView
          data={quickViewData}
          onQuickViewCloseClick={HandelQuickViewClose}
        />
      ) : (
        ""
      )}

      <Header options={options} />

      <PageTitle name="All Products" />

      <section className="shop-section shop-style-2 section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-xs-12">
              <div className="shop-area clearfix">
                <div className="woocommerce-content-wrap">
                  <div className="woocommerce-content-inner">
                    <div className="woocommerce-toolbar-top">
                      <p className="woocommerce-result-count">
                        Showing all {allProduct?.data?.length} results
                      </p>

                      <OrderingToolbar
                        HandleOrderingStatus={HandleOrderingStatus}
                        ordering={ordering}
                      />

                      <Ordering HandleSorting={HandleSorting} />
                    </div>
                    {allProduct && allProduct?.data?.length > 0 && (
                      <Products
                        HandelQuickViewData={HandelQuickViewData}
                        products={allProduct?.data}
                        ordering={ordering}
                      />
                    )}
                  </div>

                  {/* <Pagination extraClass="" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end container */}
      </section>

      <Instagram />
      <Footer />
    </Fragment>
  );
}

export default FullWidth;
