import React, { Fragment, useState } from "react";
import "./navbarRight.css";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../config/url";
import { HandleCartTotal } from "../../utils";
import { logout } from "../../redux/userSlice";
import { isAuthenticated } from "../../config/auth";

/**
 * right side of header include minicart, and buttons
 * @param options
 * @returns {*}
 * @constructor
 */
function HeaderRight({ options }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  // const { isAuthenticated } = useSelector((state) => state.user);
  const [showLoginItems, setShowLoginItems] = useState(false);

  return (
    <Fragment>
      <div className="header-right">
        <div
          className="my-account-link"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "0px 10px 10px 10px",
            zIndex: 1,
            background: "#fff",
          }}
        >
          {isAuthenticated() ? (
            <svg
              onClick={() => setShowLoginItems(!showLoginItems)}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="m8,12c-3.309,0-6-2.691-6-6S4.691,0,8,0s6,2.691,6,6-2.691,6-6,6Zm14,2v-1c0-2.206-1.794-4-4-4s-4,1.794-4,4v1h-2v7c0,1.654,1.346,3,3,3h6c1.654,0,3-1.346,3-3v-7h-2Zm-6-1c0-1.103.897-2,2-2s2,.897,2,2v1h-4v-1Zm3,7h-2v-2h2v2Zm-9,1v-7h-5c-2.757,0-5,2.243-5,5v5h11.003c-.629-.836-1.003-1.875-1.003-3Z" />
            </svg>
          ) : (
            <Link className="" to="/my-account">
              <i className="icon-user" />
            </Link>
          )}

          {showLoginItems && (
            <ul className="nav navbar-nav" style={{ marginTop: "30px" }}>
              <li>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      className="woocommerce-button button woocommerce-form-login__submit"
                      to="/orders"
                    >
                      Orders
                    </NavLink>
                  </li>
                  <li
                    onClick={() => {
                      dispatch(logout());
                      setShowLoginItems(!showLoginItems);
                    }}
                  >
                    <NavLink
                      to="#"
                      className="woocommerce-button button woocommerce-form-login__submit"
                    >
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          )}
        </div>
        {/* <div className="wishlist-box">
          <a href="#">
            <i className="icon-heart-shape-outline" />
          </a>
        </div> */}
        <div className="mini-cart">
          <button className="cart-toggle-btn" onClick={options.onMiniCartClick}>
            <i className="icon-large-paper-bag" />
            <span className="cart-count">{cart?.length}</span>
          </button>
          <div
            className={
              "mini-cart-content " +
              (options.miniCart ? "mini-cart-content-toggle" : "")
            }
          >
            <div className="mini-cart-items">
              {cart?.length > 0 &&
                cart?.map((item, index) => (
                  <div key={index} className="mini-cart-item clearfix">
                    <div className="mini-cart-item-image">
                      <NavLink to={`/product-details/${item?.id}`}>
                        <img src={API_URL + item?.mainImg} alt="" />
                      </NavLink>
                    </div>
                    <div className="mini-cart-item-des">
                      <NavLink to={`/product-details/${item?.id}`}>
                        {item?.name}
                      </NavLink>
                      <span className="mini-cart-item-quantity">
                        Qty: {item?.quantity}
                      </span>
                      <span className="mini-cart-item-price">
                        {"₦"}
                        {item?.price}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <div className="mini-cart-action clearfix">
              <span className="mini-checkout-price">
                Subtotal: {"₦"}
                {HandleCartTotal(cart)}
              </span>
              <Link className="view-cart-btn" to="/cart">
                View Cart
              </Link>
              <Link className="checkout-btn" to="/checkout">
                Checkout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default HeaderRight;
