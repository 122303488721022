import React, { Fragment, useEffect } from "react";
import { API_URL } from "../../config/url";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function CartItem({
  data,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
}) {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <tr className="cart_item">
        <td className="product-remove">
          <a
            href
            className="remove"
            title="Remove this item"
            data-product_id={data?.id}
            data-product_sku="my name is"
            onClick={() => {
              dispatch(removeFromCart(data.id));
              toast.success("Item Deleted");
            }}
            style={{
              scale: "1.6",
              color: "white",
              background: "red",
            }}
          >
            ×
          </a>
        </td>
        <td className="product-thumbnail">
          <a href={`/product-details/${data?.id}`}>
            <img
              width={57}
              height={70}
              src={API_URL + data?.mainImg}
              className="attachment-shop_thumbnail size-shop_thumbnail wp-post-image"
              alt="#"
            />
          </a>
        </td>
        <td className="product-name" data-title="Product">
          <a href={`/product-details/${data?.id}`}>{data?.name}</a>
        </td>
        <td className="product-price" data-title="Price">
          <span className="woocommerce-Price-amount amount">
            <span className="woocommerce-Price-currencySymbol">{"₦"}</span>
            {data?.price}
          </span>
        </td>
        <td className="product-quantity" data-title="Quantity">
          <div className="quantity">
            <button
              className="btn btn-default bootstrap-touchspin-up"
              type="button"
              onClick={() =>
                dispatch(
                  incrementQuantity({ id: data?.id, price: data?.price })
                )
              }
            >
              <i className="glyphicon glyphicon-chevron-up"></i>
            </button>

            <input
              type="number"
              step={1}
              min={0}
              name="cart[3c59dc048e8850243be8079a5c74d079][qty]"
              value={data?.quantity}
              title="Qty"
              className="product-count input-text qty text"
            />

            <button
              className="btn btn-default bootstrap-touchspin-down"
              type="button"
              onClick={() =>
                dispatch(
                  decrementQuantity({ id: data?.id, price: data?.price })
                )
              }
            >
              <i className="glyphicon glyphicon-chevron-down"></i>
            </button>
          </div>
        </td>
        <td className="product-subtotal" data-title="Total">
          <span className="woocommerce-Price-amount amount">
            <span className="woocommerce-Price-currencySymbol">{"₦"}</span>
            {data?.total}
          </span>
        </td>
      </tr>
    </Fragment>
  );
}

export default CartItem;
