import React, { Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import Header from "../../components/header/Header";
import "./notFound.css";
import { useDispatch } from "react-redux";
import { clearCart } from "../../redux/cartSlice";
/**
 * 404 page (Not Found)
 * @param options
 * @returns {*}
 * @constructor
 */
function SuccessPage({ options }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearCart());
  }, [0]);
  return (
    <Fragment>
      <Header options={options} />

      {/* start error-404-section */}
      <section className="error-404-pg error-404-section section-padding">
        <div className="container-1410">
          <div className="error-404-area">
            <h2>Wow!</h2>
            <div className="error-message">
              <h3> Your Purchase was successful</h3>
              <p>
                We are processing your orders and will give you a call shortly.
              </p>
              <NavLink to="/orders" className="theme-btn">
                Track Orders
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* end error-404-section */}

      <Instagram />
      <Footer />
    </Fragment>
  );
}

export default SuccessPage;
