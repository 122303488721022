import React, {Fragment} from 'react';

/**
 * Contact Widget
 * @returns {*}
 * @constructor
 */
function ContactWidget() {

    return (
        <Fragment>
            <div className="widget contact-widget">
                <h3>Contact info</h3>
                <ul>
                    <li>Phone: +234-901-829-4051</li>
                    <li>Email: Nanxtyle70@gmail.com</li>
                    {/* <li>Address: 20 Madam Bilikisu Street, Ologolo, Lagos, Nigeria</li> */}
                </ul>
            </div>
        </Fragment>
    );
}

export default ContactWidget;