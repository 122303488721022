import { WHATAPP_URL } from "../config/url";

export const HandleCartTotal = (cart) => {
  let total = 0;
  if (cart?.length > 0) {
    cart?.forEach((element) => {
      total += element?.total;
    });
    return total;
  }
  return total;
};

export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
        return true;
      }
    }
  }
  return false;
};

export const HandleWhatAppLink = ({
  name,
  price,
  title = "I got your details from your website, am interested in buying  product - ( ",
  subTitle = " ) - which cost ₦ ",
}) => {
  let whatsAppMessage = encodeURIComponent(title + name + subTitle + price);

  window.open(WHATAPP_URL + whatsAppMessage, "_blank", "noopener");
};

export function allKeysHaveValues(obj) {
  for (let key in obj) {
    if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
      return false;
    }
  }
  return true;
}
