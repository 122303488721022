import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import NewsletterWidget from "../widget/NewsletterWidget";

/**
 * Newsletter Popup component
 * @returns {*}
 * @constructor
 */
function NewsletterPopup() {
  const [showNewsletter, setShowNewsletter] = useState(false);

  const HandelNewsletterClose = () => {
    setShowNewsletter(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const showPopUp = localStorage.getItem("showPopUp");

      if (!showPopUp) {
        setShowNewsletter(true);
      }
    }, 9000);
  }, []);

  return (
    <Fragment>
      {/* start newsletter-popup-area-section */}
      <section
        className={
          "newsletter-popup-area-section " +
          (showNewsletter ? "active-newsletter-popup" : "")
        }
      >
        <div className="newsletter-popup-area">
          <div className="newsletter-popup-ineer">
            <button
              onClick={HandelNewsletterClose}
              className="btn newsletter-close-btn"
            >
              <i className="pe-7s-close-circle" />
            </button>
            <div className="img-holder">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/newsletter.jpg"}
                loading="lazy"
                alt=""
              />
            </div>

            <div className="details">
              <NewsletterWidget
                title={"Always stay updated"}
                subTitle="Subscribe to the Nanxtyles fashion newsletter to receive timely
                updates to your favorite products"
                isPopUp={true}
              />
            </div>
          </div>
        </div>
      </section>
      {/* end newsletter-popup-area-section */}
    </Fragment>
  );
}

export default NewsletterPopup;
