import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../config";

export const fetchProducts = async () => {
  const response = await apiClient.get("products");
  return response.data;
};

export const postEmailVerification = async (email) => {
  const response = await apiClient.post("verify-email", { email });
  return response.data;
};

export const postOTPVerification = async (data) => {
  const response = await apiClient.post("verify-otp", { ...data });
  return response.data;
};

export const registerUser = async (data) => {
  const response = await apiClient.post("register", { ...data });
  return response.data;
};

export const login = async (data) => {
  const response = await apiClient.post("login", { ...data });
  return response.data;
};

export const subscribe = async (data) => {
  const response = await apiClient.post("subscribe", { ...data });
  return response.data;
};

export const orders = async (token) => {
  const response = await apiClient.get("orders", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const checkout = async (data, token) => {
  const response = await apiClient.post(
    "checkout",
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await apiClient.post("login", { email, password });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);
