// src/utils/auth.js

import { jwtDecode } from "jwt-decode";

/**
 * Retrieves the token from localStorage.
 * @returns {string|null} JWT token or null if not found.
 */
export const getToken = () => {
  return localStorage.getItem("user");
};

/**
 * Sets the token in localStorage.
 * @param {string} token - JWT token to store.
 */
export const setToken = (token) => {
  localStorage.setItem("user", token);
};

/**
 * Removes the token from localStorage.
 */
export const removeToken = () => {
  localStorage.removeItem("user");
};

/**
 * Checks if the token is valid and not expired.
 * @returns {boolean} True if valid, false otherwise.
 */
export const isAuthenticated = () => {
  const token = JSON.parse(getToken());
  if (!token) return false;
  try {
    const { exp } = jwtDecode(token?.user?.token);
    if (!exp) return false;
    const currentTime = Date.now() / 1000; // Convert to seconds
    if (!(exp > currentTime)) {
      removeToken();
    }
    return exp > currentTime;
  } catch (error) {
    console.error("Invalid token:", error);
    return false;
  }
};
