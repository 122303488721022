import React, { Fragment, useEffect } from "react";
import featuredProductsData from "../../data/featured-products.json";
import { Link } from "react-router-dom";
import { useProducts } from "../../hooks/useProduct";
import Preloader from "../global/Preloader";
import { API_URL } from "../../config/url";

/**
 * Featured Products Component
 * @returns {*}
 * @constructor
 */
function FeaturedProducts() {
  const { data, isLoading } = useProducts();

  useEffect(() => {
    if (isLoading) {
      <Preloader />;
    }
  }, [isLoading]);

  return (
    <Fragment>
      {/* start featured-proeducts-section-s2 */}
      <section className="featured-proeducts-section-s2 section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-xs-12">
              <div className="product-grids clearfix">
                {data &&
                  data?.data?.length > 0 &&
                  data?.data
                    ?.filter((item) => item?.isFeatured === true)
                    ?.slice(0, 3)
                    ?.map((item, index) => (
                      <div key={index} className="grid">
                        <div className="img-holder">
                          <a href="#">
                            <img
                              loading="lazy"
                              src={API_URL + item?.mainImg}
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="details">
                          <h3>{item.title}</h3>
                          <Link className="shop-btn" to="/products">
                            Shop Now
                          </Link>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
        {/* end container-1410 */}
      </section>
      {/* end featured-proeducts-section-s2 */}
    </Fragment>
  );
}

export default FeaturedProducts;
