import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { subscribe } from "../../services";

/**
 * Newsletter Widget
 * @returns {*}
 * @constructor
 */
function NewsletterWidget({
  title = "Sign Up Now and Get 10% Off",
  subTitle = "Get timely updates from your favorite products",
  isPopUp = false,
}) {
  const [email, setEmail] = useState("");
  const [subcribeText, setSubcribeText] = useState("Subcribe");

  const handleEmailSubscription = async (event) => {
    event.preventDefault();
    if (!email) {
      toast.warn("Email is required");
      return null;
    }
    setSubcribeText("Loading.....");

    try {
      const response = await subscribe({ email });
      setSubcribeText("Subcribe");

      if (response) {
        toast.success(response?.message);
        setEmail("");
      }
    } catch (error) {
      setSubcribeText("Subcribe");

      toast.error(`${error?.response?.data?.error}`);
    }
  };

  const handleNotShowModal = (event) => {
    if (event?.target?.checked) {
      localStorage.setItem("showPopUp", event?.target?.checked);
    } else {
      localStorage.removeItem("showPopUp");
    }
  };
  return (
    <Fragment>
      <div className="widget newsletter-widget">
        <div className="inner">
          <h3> {title} </h3>
          <p> {subTitle} </p>
          <form onSubmit={handleEmailSubscription}>
            <div className="input-1">
              <input
                type="email"
                className="form-control"
                placeholder="Email Address *"
                onChange={(e) => setEmail(e?.target?.value)}
                required
                value={email}
              />
            </div>
            <div className="submit clearfix">
              <button
                type="submit"
                style={isPopUp ? { width: "100%", margin: "5px 0px" } : {}}
              >
                {subcribeText}
              </button>
            </div>
          </form>
        </div>
        {isPopUp && (
          <>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
              }}
            ></div>
            <form>
              <div>
                <label className="checkbox-holder" htmlFor="checkbox">
                  Don't show this popup again!
                  <input
                    type="checkbox"
                    onClick={handleNotShowModal}
                    className="show-message"
                    id="checkbox"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </form>
          </>
        )}
      </div>
    </Fragment>
  );
}

export default NewsletterWidget;
