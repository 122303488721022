import React, { Fragment, useEffect } from "react";
import Slider from "react-slick";
import ReactTooltip from "react-tooltip";
import "../products/products.css";
import { Link } from "react-router-dom";
import { settings } from "../../config/slider";
import { API_URL } from "../../config/url";
import Preloader from "../global/Preloader";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import { useProducts } from "../../hooks/useProduct";
import { toast } from "react-toastify";
/**
 * Recent Products component
 * @param onQuickViewClick
 * @returns {*}
 * @constructor
 */
function RecentProducts({ onQuickViewClick }) {
  const { data, error, isLoading } = useProducts();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      <Preloader />;
    }
  }, [isLoading]);

  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <Fragment>
      {/* start trendy-product-section */}
      <section className="trendy-product-section section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-xs-12">
              <div className="section-title-s2">
                <h2>Recent products</h2>
              </div>
              <Link className="more-products" to="/products">
                More products
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <div className="products-wrapper">
                <ul className="products product-row-slider">
                  <Slider {...settings}>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item, index) => (
                        <li key={index} className="product">
                          <div className="product-holder">
                            {parseInt(item?.price) <
                            parseInt(item?.oldPrice) ? (
                              <div className="product-badge discount">
                                {Math.round(
                                  ((parseInt(item?.price) -
                                    parseInt(item?.oldPrice)) /
                                    parseInt(item?.price)) *
                                    100
                                )}
                                %
                              </div>
                            ) : (
                              ""
                            )}

                            <Link to={`/product-details/${item.id}`} className="productImageWrapper" >
                              <img
                                loading="lazy"
                                src={API_URL + item?.mainImg}
                                alt=""
                                style={{ height: "100%" }}
                              />
                            </Link>

                            <div className="shop-action-wrap">
                              <ul className="shop-action">
                                <li>
                                  <a
                                    href
                                    title="Quick view!"
                                    data-tip="Quick view!"
                                    onClick={(e) => onQuickViewClick(e, item)}
                                  >
                                    <i className="fi flaticon-view" />
                                  </a>
                                </li>
                                {/* <li>
                                  <a
                                    href
                                    title="Add to Wishlist!"
                                    data-tip="Add to Wishlist!"
                                  >
                                    <i className="fi icon-heart-shape-outline" />
                                  </a>
                                </li> */}
                                <li>
                                  <a
                                    href
                                    title="Add to cart!"
                                    data-tip="Add to cart!"
                                    onClick={() => {
                                      dispatch(addToCart(item));
                                      toast.success("Item added to cart");
                                    }}
                                  >
                                    <i className="fi flaticon-shopping-cart" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <h4>
                              <Link to={`/product-details/${item.id}`}>
                                {item?.name}
                              </Link>
                            </h4>
                            <span className="woocommerce-Price-amount amount">
                              <ins>
                                <span className="woocommerce-Price-amount amount">
                                  <bdi>
                                    <span className="woocommerce-Price-currencySymbol">
                                      {"₦"}
                                    </span>
                                    {item?.price}
                                  </bdi>
                                </span>
                              </ins>
                              {parseInt(item?.price) <
                              parseInt(item?.oldPrice) ? (
                                <del>
                                  <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol">
                                        {"₦"}
                                      </span>
                                      {item?.oldPrice}
                                    </bdi>
                                  </span>
                                </del>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </li>
                      ))
                    ) : (
                      <></>
                    )}
                  </Slider>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* end container-1410 */}
      </section>
      {/* end trendy-product-section */}
      <ReactTooltip className="tool-tip" effect="solid" />
    </Fragment>
  );
}

export default RecentProducts;
