import React, { useState } from "react";
import { Fragment } from "react";
import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import Header from "../../components/header/Header";
import PageTitle from "../../components/global/PageTitle";

import BillingFields from "./BillingFields";
import NoscriptSnippet from "../../components/global/NoscriptSnippet";
import CheckoutRegs from "./checksBtn";
import { useSelector } from "react-redux";
import {
  allKeysHaveValues,
  HandleCartTotal,
  HandleWhatAppLink,
} from "../../utils";
import { toast } from "react-toastify";
import { checkout } from "../../services";
import { WHATAPP_URL } from "../../config/url";

/**
 * Checkout page
 * @param options
 * @returns {*}
 * @constructor
 */
function Checkout({ options }) {
  const cart = useSelector((state) => state.cart.cart);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [formData, setFormData] = useState({});
  const [submitText, setSubmitText] = useState("Place order");

  const handleCheckOut = async (e) => {
    setSubmitText("Loading");
    e.preventDefault();
    if (!allKeysHaveValues(formData)) {
      toast.error("Fill all billing datails fields");
    }

    if (
      (paymentMethod?.whatsapp_method && paymentMethod?.PayPal_method) ||
      (!paymentMethod?.whatsapp_method && !paymentMethod?.PayPal_method)
    ) {
      setSubmitText("Place order");
      toast.error("select one payment method");
      return;
    }

    try {
      if (paymentMethod?.whatsapp_method) {
        const name = JSON.stringify(cart?.map((item) => item?.name));
        const price = JSON.stringify(cart?.map((item) => item?.total));
        HandleWhatAppLink({ name, price });
      }

      if (paymentMethod?.PayPal_method) {
        if (!isAuthenticated) {
          toast.error("Please login or register to proceed");
          return;
        }
        const data = {
          cartItems: cart,
          userId: user?.data?.id,
          email: user?.data?.email,
          billingDetails: formData,
        };
        setSubmitText("Place order");

        const response = await checkout(data, user?.token);
        window.location.href = response?.authorizationUrl;
      }
    } catch (error) {
      setSubmitText("Place order");
      toast.error(error?.response?.data?.error);
    }
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectPaymentMethod = (event) => {
    setPaymentMethod({
      ...paymentMethod,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Fragment>
      <Header options={options} />

      <PageTitle name="Checkout" />

      {/* start checkout-section */}
      <section className="checkout-section section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-xs-12">
              <div className="woocommerce">
                {!isAuthenticated && <CheckoutRegs />}

                {/* <div className="woocommerce-info">
                  Have a coupon?{" "}
                  <a
                    onClick={HandelShowCouponStatus}
                    className="showcoupon"
                  >
                    Click here to enter your code
                  </a>
                </div>
                {showShowCoupon ? (
                  <form className="checkout_coupon" method="post">
                    <p className="form-row form-row-first">
                      <input
                        type="text"
                        name="coupon_code"
                        className="input-text"
                        placeholder="Coupon code"
                        id="coupon_code"
                      />
                    </p>
                    <p className="form-row form-row-last">
                      <input
                        type="submit"
                        className="button"
                        name="apply_coupon"
                        defaultValue="Apply Coupon"
                      />
                    </p>
                    <div className="clear" />
                  </form>
                ) : (
                  ""
                )} */}

                <form
                  name="checkout"
                  onSubmit={handleCheckOut}
                  className="checkout woocommerce-checkout"
                  encType="multipart/form-data"
                >
                  <div className="col2-set" id="customer_details">
                    <BillingFields handleInputChange={handleInputChange} />
                    {/* <ShippingFields /> */}
                  </div>
                  <h3 id="order_review_heading">Order Summary</h3>
                  <div
                    id="order_review"
                    className="woocommerce-checkout-review-order"
                  >
                    <table className="shop_table woocommerce-checkout-review-order-table">
                      <thead>
                        <tr>
                          <th className="product-name">Items</th>
                          <th className="product-total">Total</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                      <tfoot>
                        <tr className="cart-subtotal">
                          <th>Subtotal</th>
                          <td>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">
                              {"₦"}
                              </span>
                              {HandleCartTotal(cart)}
                            </span>
                          </td>
                        </tr>
                        <tr className="shipping">
                          <th>Shipping</th>
                          <td data-title="Shipping">
                            Free Shipping
                            <input
                              type="hidden"
                              name="shipping_method[0]"
                              data-index={0}
                              id="shipping_method_0"
                              defaultValue="free_shipping:1"
                              className="shipping_method"
                            />
                          </td>
                        </tr>
                        <tr className="order-total">
                          <th>Total</th>
                          <td>
                            <strong>
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  {"₦"}
                                </span>

                                {HandleCartTotal(cart)}
                              </span>
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <div id="payment" className="woocommerce-checkout-payment">
                      <ul className="wc_payment_methods payment_methods methods">
                        <li className="wc_payment_method payment_method_cheque">
                          <input
                            id="payment_method_cheque"
                            type="checkbox"
                            className="input-radio"
                            name="whatsapp_method"
                            // defaultValue="cheque"
                            // defaultChecked="checked"
                            data-order_button_text
                            onChange={handleSelectPaymentMethod}
                          />
                          <span
                            className="grop-woo-radio-style"
                            style={{ margin: "0px 5px" }}
                          />
                          <label htmlFor="payment_method_cheque">
                            Whatsapp payment
                          </label>
                          <div className="payment_box payment_method_cheque">
                            <p>
                              Please note that payment via whatsapp will not be
                              stored in our datebase.
                            </p>
                          </div>
                        </li>
                        <li className="wc_payment_method payment_method_paypal">
                          <input
                            id="payment_method_paypal"
                            type="checkbox"
                            className="input-radio"
                            name="PayPal_method"
                            // defaultValue="paypal"
                            data-order_button_text="Proceed to Paystack"
                            onChange={handleSelectPaymentMethod}
                          />
                          {/*grop add span for radio button style*/}
                          <span
                            className="grop-woo-radio-style"
                            style={{ margin: "0px 5px" }}
                          />
                          {/*custom change*/}
                          <label htmlFor="payment_method_paypal">
                            Paystack{" "}
                            {/* <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/paypal.png"
                              }
                              alt="Paystack Acceptance Mark"
                            /> */}
                            {/* <a
                              href="#"
                              className="about_paypal"
                              title="What is Paystack?"
                            >
                              What is Paystack?
                            </a>{" "} */}
                          </label>
                          <div
                            className="payment_box payment_method_paypal"
                            style={{ display: "none" }}
                          >
                            <p>
                              Pay via Paystack; you can pay with your credit card
                              if you don’t have a Paystack account.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="form-row place-order">
                        <NoscriptSnippet />

                        <input
                          type="submit"
                          className="button"
                          // name="woocommerce_checkout_place_order"
                          // id="place_order"
                          value={submitText}
                          // defaultValue={submitText}
                          // data-value={submitText}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end checkout-section */}

      <Instagram />
      <Footer />
    </Fragment>
  );
}

export default Checkout;
