import React, { useState, Fragment, useEffect } from "react";
import Slider from "react-slick";

import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import PageTitle from "../../components/global/PageTitle";
import Header from "../../components/header/Header";
import ProductInfoTabs from "../../components/products/ProductInfoTabs";
import QuickView from "../../components/products/QuickView";
import RecentSingleProducts from "../../components/products/RecentSingleProducts";

import "./shop.css";

/**
 * demo data
 */
// import data from "../../data/singleProductDemo.json";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";
import { API_URL } from "../../config/url";
import WhatsAppCTA from "../../components/CTA/WhatsAppCTA";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
} from "../../redux/cartSlice";
import { useProducts } from "../../hooks/useProduct";
import { toast } from "react-toastify";

/**
 * single shop page with  Slider Images
 * @param options
 * @returns {*}
 * @constructor
 */
function ShopSliderImages({ options }) {
  const dispatch = useDispatch();
  const { data, error, isLoading } = useProducts();
  const { id } = useParams();
  const [singleProduct, setSingleProduct] = useState([]);
  const cart = useSelector((state) => state.cart.cart);
  const [singleProductInCart, setSingleProductInCart] = useState({});

  useEffect(() => {
    if (data?.data?.length > 0) {
      const result = data?.data?.find((item) => item.id == id);
      setSingleProduct(result);
      // JSON.parse(result?.images)?.map((item, index) =>
      //   console.log("items", item)
      // );
    }
  }, [id, singleProduct, data]);

  useEffect(() => {
    // HandleAddToCart();
  }, [singleProductInCart]);

  const HandleQty = () => {
    const item = cart.find((cartItem) => cartItem.id == id);
    console.log("items ", item);
    if (item) {
      setSingleProductInCart(item);
    }
  };

  const HandleAddToCart = () => {
    dispatch(addToCart(singleProduct));
    HandleQty();
    toast.success("Item added to cart");
  };

  /**
   * states
   */
  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewData, setQuickViewData] = useState({});
  const [productCount, setProductCount] = useState(1);

  /**
   * Handle Product Count
   */
  const HandleProductCount = (e, data) => {
    e.preventDefault();
    if (data == "plus") {
      setProductCount(productCount + 1);
    } else {
      if (productCount > 1) {
        setProductCount(productCount - 1);
      } else {
        setProductCount(1);
      }
    }
  };

  /**
   * Handel Quick View Data
   */
  const HandelQuickViewData = (e, item) => {
    e.preventDefault();
    setShowQuickView(!showQuickView);
    setQuickViewData(item);
  };

  /**
   * Handel Quick View Close
   */
  const HandelQuickViewClose = (e) => {
    e.preventDefault();
    setShowQuickView(false);
    setQuickViewData({});
  };

  /**
   * slider settings
   */
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Fragment>
      {showQuickView ? (
        <QuickView
          data={quickViewData}
          onQuickViewCloseClick={HandelQuickViewClose}
        />
      ) : (
        ""
      )}

      <Header options={options} />

      <PageTitle name="Product Details" />

      {singleProduct && Object.entries(singleProduct)?.length > 0 && (
        <section className="shop-single-section section-padding">
          <div className="container-1410">
            <div className="row">
              <div className="col col-md-6">
                <div className="shop-single-slider slider-thumbnail">
                  <Slider {...settings}>
                    {JSON.parse(singleProduct?.images)?.map((item, index) => (
                      <div key={index}>
                        <img src={API_URL + item.src} alt="" />
                      </div>
                    ))}
                  </Slider>
                  <div className="slider-nav"></div>
                </div>
              </div>
              <div className="col col-md-6">
                <div className="product-details">
                  <h2>{singleProduct?.name}</h2>
                  <div className="price">
                    <span className="current">
                      {singleProduct?.Symbol}
                      {singleProduct?.price}
                    </span>
                    <span className="old">
                      {singleProduct?.Symbol}
                      {singleProduct?.oldPrice}
                    </span>
                  </div>
                  <div className="rating">
                    <i className="fi flaticon-star" />
                    <i className="fi flaticon-star" />
                    <i className="fi flaticon-star" />
                    <i className="fi flaticon-star" />
                    <i className="fi flaticon-star-social-favorite-middle-full" />
                    <span>{singleProduct?.reviewCounts}</span>
                  </div>
                  <p>{singleProduct?.shortDescription}</p>
                  <div className="product-option">
                    <form className="form">
                      <div className="product-row">
                        <div className="touchspin-wrap">
                          <button
                            onClick={(e) => {
                              dispatch(
                                incrementQuantity({
                                  id: singleProduct?.id,
                                  price: singleProduct?.price,
                                })
                              );
                              HandleQty();
                            }}
                            id="slider-thumbnail-touchspin-up"
                            className="btn btn-default "
                            type="button"
                          >
                            <i className="glyphicon glyphicon-chevron-up"></i>
                          </button>
                          <button
                            onClick={(e) => {
                              dispatch(
                                decrementQuantity({
                                  id: singleProduct?.id,
                                  price: singleProduct?.price,
                                })
                              );
                              HandleQty();
                            }}
                            id="slider-thumbnail-touchspin-down"
                            className="btn btn-default "
                            type="button"
                          >
                            <i className="glyphicon glyphicon-chevron-down"></i>
                          </button>
                          <input
                            readOnly
                            className="product-count"
                            type="text"
                            // defaultValue={1}
                            value={singleProductInCart?.quantity}
                            name="product-count"
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() => HandleAddToCart()}
                          >
                            Add to cart
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="thb-product-meta-before">
                    {/* <div className="add-to-wishlist">
                      <a href="#" className="add_to_wishlist">
                        <i className="pe-7s-like" />
                        <span>Add To Wishlist</span>
                      </a>
                    </div> */}
                    <div className="product_meta">
                      {/* <span className="sku_wrapper">
                        SKU: <span className="sku">{singleProduct?.sku}</span>
                      </span> */}
                      <span className="posted_in">
                        Categories:
                        {singleProduct?.Categories.map((item, index) => (
                          <a key={index} href={item.link}>
                            {item.name}{" "}
                            {singleProduct?.Categories.length - 1 === index
                              ? ""
                              : ","}
                          </a>
                        ))}
                      </span>
                      <span className="tagged_as">
                        Tags:
                        {singleProduct?.Tags.map((item, index) => (
                          <a key={index} href={item.link}>
                            {item.name}{" "}
                            {singleProduct?.Tags.length - 1 === index
                              ? ""
                              : ","}
                          </a>
                        ))}
                      </span>
                      <span>
                        <WhatsAppCTA data={singleProduct} />
                      </span>
                      <div className="wc-proceed-to-checkout">
                        <Link
                          className="checkout-button button alt wc-forward"
                          to="/checkout"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                            marginTop: "50px",
                            borderRadius: "25px",
                            border: "none",
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "50px",
                            color: "white",
                          }}
                        >
                          Proceed to Checkout
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* end row */}
            <div className="row">
              <div className="col col-md-8 col-md-offset-2">
                <ProductInfoTabs
                  description={singleProduct?.description}
                  review={singleProduct?.Reviews}
                />
              </div>
            </div>
            {/* end row */}
            <div className="row">
              <div className="col col-xs-12">
                {/* <RecentSingleProducts onQuickViewClick={HandelQuickViewData} /> */}
              </div>
            </div>
          </div>
          {/* end of container */}
        </section>
      )}
      <Instagram />
      <Footer />
    </Fragment>
  );
}

export default ShopSliderImages;
