import React, { Fragment, useEffect } from "react";

import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import PageTitle from "../../components/global/PageTitle";
import Header from "../../components/header/Header";
import CartItem from "../../components/cart/CartItem";
import Coupon from "../../components/cart/Coupon";
import CalculatedShipping from "../../components/cart/CalculatedShipping";

import { useDispatch, useSelector } from "react-redux";
import {
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} from "../../redux/cartSlice";
import "./cart.css";
import { HandleCartTotal } from "../../utils";

/**
 * Cart page
 * @param options
 * @returns {*}
 * @constructor
 */
function Cart({ options }) {
  const cart = useSelector((state) => state.cart.cart);
  

  useEffect(() => {
  }, [cart]);

  return (
    <Fragment>
      <Header options={options} />

      <PageTitle name="Cart" />

      {/* start cart-section */}
      <section className="cart-section woocommerce-cart section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-xs-12">
              <div className="woocommerce">
                <form action="/" method="post">
                  <table className="shop_table shop_table_responsive cart">
                    <thead>
                      <tr>
                        <th className="product-remove">&nbsp;</th>
                        <th className="product-thumbnail">&nbsp;</th>
                        <th className="product-name">Product</th>
                        <th className="product-price">Price</th>
                        <th className="product-quantity">Quantity</th>
                        <th className="product-subtotal">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart?.map((item, index) => (
                        <CartItem
                          key={index}
                          data={item}
                          removeFromCart={removeFromCart}
                          incrementQuantity={incrementQuantity}
                          decrementQuantity={decrementQuantity}
                        />
                      ))}
                      {/* <Coupon /> */}
                    </tbody>
                  </table>
                </form>
                <div className="cart-collaterals">
                  <CalculatedShipping
                    currencySymbol="₦"
                    price={HandleCartTotal(cart)}
                    data={cart[0]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end cart-section */}

      <Instagram />
      <Footer />
    </Fragment>
  );
}

export default Cart;
