import React, { Fragment } from "react";

import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import Header from "../../components/header/Header";
import PageTitle from "../../components/global/PageTitle";

import RegisterUser from "./register";
import LoginUser from "./login";

function MyAccount({ options }) {
  return (
    <Fragment>
      <Header options={options} />
      <PageTitle name="My Account" />
      <section className="my-account-section">
        <div className="container-1410">
          <div className="row">
            <div className="col-xs-12">
              <div className="woocommerce">
                <div className="woocommerce-notices-wrapper" />
                <div className="u-columns col2-set" id="customer_login">
                  <LoginUser />
                  <RegisterUser />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Instagram />
      <Footer />
    </Fragment>
  );
}

export default MyAccount;
