import React, { Fragment, useEffect, useState } from "react";
import ClientReviewItem from "./ClientReviewItem";
import AddReview from "./AddReview";
import { API_URL } from "../../config/url";

/**
 * Product Info Tabs Component
 * @returns {*}
 * @constructor
 */
function ProductInfoTabs({ review, description }) {
  const [openTab, setOpenTab] = useState(1);

  const HandleOpenTabStatus = (event, data) => {
    event.preventDefault();
    setOpenTab(data);
  };

  const handleDate = (val) => new Date(val).toLocaleDateString();

  return (
    <Fragment>
      <div className="single-product-info">
        {/* Nav tabs */}
        <ul className="nav nav-tabs" role="tablist">
          <li
            onClick={(event) => {
              HandleOpenTabStatus(event, 1);
            }}
            className={" " + (openTab == 1 ? "active" : "")}
          >
            <a href="#01" data-toggle="tab">
              Description
            </a>
          </li>
          {/* <li
            onClick={(event) => {
              HandleOpenTabStatus(event, 2);
            }}
            className={" " + (openTab == 2 ? "active" : "")}
          >
            <a href="#02" data-toggle="tab">
              Review ( {review?.length} )
            </a>
          </li> */}
        </ul>
        {/* Tab panes */}
        <div className="tab-content">
          <div
            role="tabpanel"
            className={"tab-pane fade " + (openTab == 1 ? "in active" : "")}
            id="01"
          >
            <p> {description} </p>
          </div>
          <div
            role="tabpanel"
            className={"tab-pane fade " + (openTab == 2 ? "in active" : "")}
            id="02"
          >
            <div className="row">
              <div className="col col-xs-12">
                {review && review?.length > 0 ? (
                  review?.map((rev) => (
                    <ClientReviewItem
                      img={
                        API_URL + rev?.pic
                        // ? rev?.pic
                        // : "/assets/images/shop/shop-single/review/img-1.jpg"
                      }
                      time={handleDate(rev?.createdAt)}
                      text={rev?.comment}
                    />
                  ))
                ) : (
                  <h4>No review for this product yet</h4>
                )}
              </div>
              {/* end col */}
              <div className="col col-xs-12 review-form-wrapper">
                <AddReview />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ProductInfoTabs;
