import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import Header from "../../components/header/Header";
import "./notFound.css";
import { useSelector } from "react-redux";
import { orders } from "../../services";
import { toast } from "react-toastify";
import CartItem from "../../components/cart/CartItem";
import { API_URL } from "../../config/url";

/**
 * 404 page (Not Found)
 * @param options
 * @returns {*}
 * @constructor
 */
function OrderPage({ options }) {
  const { user } = useSelector((state) => state.user);
  const [order, setOrder] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await orders(user?.token);
        setOrder(response);
      } catch (error) {
        toast.error(error?.response?.data?.error);
      }
    }
    fetchData();
  }, [user?.token]);
  return (
    <Fragment>
      <Header options={options} />

      <section className="cart-section woocommerce-cart section-padding">
        <div className="container-1410">
          <section className="error-404-pg   section-padding">
            <div className="container-1410">
              <div className="error-404-area">
                <div className="error-message">
                  <h3>Track your orders</h3>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col col-xs-12">
              <div className="woocommerce">
                <form action="/" method="post">
                  <table className="shop_table shop_table_responsive cart">
                    <thead>
                      <tr>
                        <th className="product-thumbnail">&nbsp;</th>
                        <th className="product-name">Product</th>
                        <th className="product-subtotal">Total</th>
                        <th className="product-subtotal">Status</th>
                      </tr>
                    </thead>
                    {order && order?.data?.data?.length > 0 && (
                      <tbody>
                        {order?.data?.data?.map((data, index) => (
                          <tr className="cart_item">
                            <td
                              className="product-thumbnail"
                              style={{ minWidth: "280px" }}
                            >
                              {data?.OrderItems?.map((item, index_) => (
                                <div>
                                  <img
                                    width={57}
                                    height={70}
                                    src={API_URL + item?.Product?.mainImg}
                                    className="attachment-shop_thumbnail size-shop_thumbnail wp-post-image"
                                    alt="#"
                                    key={index_}
                                  />
                                  <div>
                                    Price:
                                    <span>
                                      {"₦"} {item?.price}
                                    </span>
                                  </div>
                                  <div>
                                    Qty:
                                    <span> {item?.quantity} </span>
                                  </div>
                                </div>
                              ))}
                            </td>

                            <td className="product-name" data-title="Product">
                              <div>
                                <div>
                                  <span> billing_address_1 </span> :
                                  <span>
                                    {data?.billingDetails?.billing_address_1}
                                  </span>
                                </div>
                                <div>
                                  <span> billing_address_2 </span> :
                                  <span>
                                    {data?.billingDetails?.billing_address_2}
                                  </span>
                                </div>
                                <div>
                                  <span> billing_city </span> :
                                  <span>
                                    {data?.billingDetails?.billing_city}
                                  </span>
                                </div>
                                <div>
                                  <span> billing_country </span> :
                                  <span>
                                    {data?.billingDetails?.billing_country}
                                  </span>
                                </div>
                                <div>
                                  <span> billing_postcode8 </span> :
                                  <span>
                                    {data?.billingDetails?.billing_postcode8}
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td className="product-subtotal" data-title="Total">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  {"₦"}
                                </span>
                                {data?.totalAmount}
                              </span>
                            </td>

                            <td className="product-subtotal" data-title="Total">
                              <span className="woocommerce-Price-amount amount">
                                {data?.status}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Instagram />
      <Footer />
    </Fragment>
  );
}

export default OrderPage;
